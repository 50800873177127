import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Countdown from './Countdown';

import { toast } from 'react-toastify';
import RouteUtils from '../Utils';

export default class RouteOptimizerMetrics extends React.Component {
    static propTypes = {
        routeSchedules: PropTypes.object.isRequired,
        fetchMetrics: PropTypes.bool.isRequired,
        loadedRoutes: PropTypes.object,
        updateMetrics: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loadedRoutes: props.loadedRoutes || {},
            loadingMetrics: false,
            rateLimitMet: false,
            unknownError: false
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        if (Object.keys(this.props.routeSchedules).length > 0 && this.props.fetchMetrics) {
            this.getMetrics();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.fetchMetrics) {
            if (JSON.stringify(prevProps.routeSchedules) !== JSON.stringify(this.props.routeSchedules) && Object.keys(prevProps.routeSchedules).length > 0) {
                this.getMetrics();
            }
        } else {
            if (JSON.stringify(prevProps.loadedRoutes) !== JSON.stringify(this.props.loadedRoutes)) {
                this.setState({ loadedRoutes: this.props.loadedRoutes });
            }
        }
    }

    getMetrics = (user_id = Object.keys(this.props.routeSchedules)[0], callback = true) => {
        this.setState({ rateLimitMet: false, loadingMetrics: true });

        const allUsers = Object.keys(this.props.routeSchedules);

        $.ajax({
            method: "GET",
            url: "/reports/route/metrics.json",
            data: { user_id }
        }).done(res => {
            const loadedRoutes = { ...this.state.loadedRoutes, [user_id]: res }
            this.setState({ loadedRoutes });

            if (callback) {
                const index = allUsers.findIndex(id => id === user_id);
                if ((index + 1) < this.numberOfRoutes()) {
                    this.getMetrics(allUsers[index + 1]);
                } else {
                    this.setState({ loadingMetrics: false })

                    if (this.props.updateMetrics) this.props.updateMetrics(loadedRoutes);
                }
            } else {
                this.setState({ loadingMetrics: false })

                if (this.props.updateMetrics) this.props.updateMetrics(this.state.loadedRoutes);
            }
        }).fail(res => {
            if (res.responseJSON && res.responseJSON.error === "rate_limited") {
                this.setState({ rateLimitMet: true });
                setTimeout(() => {
                    this.getMetrics(user_id);
                }, 30000)
            } else {
                toast.error("Unable to fetch Route Metrics...", {
                    position: toast.POSITION.TOP_CENTER
                });

                this.setState({ loadingMetrics: false, unknownError: true });
            }
        });
    };

    numberOfRoutes = () => {
        return Object.keys(this.props.routeSchedules).length;
    };

    numberOfLoadedRoutes = () => {
        return Object.keys(this.state.loadedRoutes).length;
    };

    render() {
        return(
            <div className="display-flex justify-content-space-between">
                <div className="text-center">
                    <h4 className="color-gray">
                        Total Stops:
                    </h4>
                    <h3>
                        { RouteUtils.routeToTotalStops(this.props.loadedRoutes || this.props.routeSchedules) }
                    </h3>
                </div>

                <div className="text-center">
                    <h4 className="color-gray">
                        Total Drive Time:
                    </h4>
                    { this.state.loadingMetrics &&
                        <h5>
                            Calculating Total Drive Time for Route { this.numberOfLoadedRoutes() + 1 } / { this.numberOfRoutes() }
                        </h5>
                    }
                    { (this.state.loadingMetrics && !this.state.rateLimitMet) &&
                        <div className="display-flex flex-column align-items-center">
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                        </div>
                    }
                    { (!this.state.loadingMetrics && !this.state.rateLimitMet && !this.state.unknownError) &&
                        <h3>
                            { RouteUtils.routeToTotalDuration(this.state.loadedRoutes) }
                        </h3>
                    }
                    { this.state.rateLimitMet &&
                        <div className="alert alert-warning">
                            Too many reqeusts, retrying in:
                            <h5>
                                <Countdown
                                    amount={30}
                                />
                            </h5>
                        </div>
                    }
                    { this.state.unknownError &&
                        <div className="alert alert-warning">
                            Route Metrics are Currently Unavailable
                        </div>
                    }
                </div>

                <div className="text-center">
                    <h4 className="color-gray">
                        Total Miles:
                    </h4>
                    { this.state.loadingMetrics &&
                        <h5>
                            Calculating Miles for Route { this.numberOfLoadedRoutes() + 1 } / { this.numberOfRoutes() }
                        </h5>
                    }
                    { (this.state.loadingMetrics && !this.state.rateLimitMet) &&
                        <div className="display-flex flex-column align-items-center">
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                        </div>
                    }
                    { (!this.state.loadingMetrics && !this.state.rateLimitMet && !this.state.unknownError) &&
                        <h3>
                            { RouteUtils.routeToTotalDistance(this.state.loadedRoutes) }
                        </h3>
                    }
                    { this.state.rateLimitMet &&
                        <div className="alert alert-warning">
                            Too many reqeusts, retrying in:
                            <h5>
                                <Countdown
                                    amount={30}
                                />
                            </h5>
                        </div>
                    }
                    { this.state.unknownError &&
                        <div className="alert alert-danger">
                            Unkown Error Occurred
                        </div>
                    }
                </div>
            </div>
        )
    }
};
