const nextOccuringDate = (day, timezone) => {
    const targetDay = moment().tz(timezone).isoWeekday(day).day();
    const today = moment().tz(timezone).isoWeekday();

    if (today > targetDay) {
        return moment().startOf('isoWeek').add(1, 'week').tz(timezone).day(day)
    } else {
        return moment().tz(timezone).isoWeekday(day);
    }
};

export default {
    humanize(string) {
        let parts = [];

        if (string.match(/\_/)) {
            parts = string.split("_");
        } else if (string.match(/\s/)) {
            parts = string.split(" ");
        } else if (string.match(/\-/)) {
            parts = string.split("-");
        } else {
            parts = [string];
        }

        return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(" ")
    },

    formattedPhone(phone) {
        const regex = /^(\+1|)?(\d{3})(\d{3})(\d{4})$/;
        const matched = phone.match(regex);

        if (matched) {
            let string = matched[1] || "";
            return string += `(${matched[2]}) ${matched[3]}-${matched[4]}`;
        }

        return "";
    },

    floatToCurrency(float, currencySymbol = true) {
        const value = `${float.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;

        if (currencySymbol) return `$${value}`

        return value;
    },

    floatToPercentage(float) {
        return `${float * 100}%`
    },

    truncate(string, length) {
        if (string.length > length) {
            return `${string.substr(0, length)}...`;
        } else {
            return string;
        }
    },

    pad(n, width, z = '0') {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },

    dataURItoBlob(dataURI, callback) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab]);
        callback(bb);
    },

    blobToDataURI(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            }
        });
    },

    linkifyRawUrls(string) {
        // remove any url's that may already be in an anchor tag by removing the entire anchor tag
        const filtered = string.replace(/<a.*\<\/a\>/gm, "");

        const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/gm;
        const rawMatches = filtered.match(regex);

        // NOTE: this will not account for urls that match in the filtered out anchor tags (e.g. <a href="https://www.google.com">Google</a> https://www.google.com)

        if (rawMatches) {
            rawMatches.forEach((match) => {
                let newMatch = match;
                if (match.match(/^(?!http|https)/gm)) newMatch = `https://${match}`;
                string = string.replace(match, `<a href='${newMatch}' target="_blank">${newMatch}</a>`);
            });
        }

        return string;
    },

    diffOfArrays(arr1, arr2) {
        return arr1.filter(i => arr2.indexOf(i) < 0);
    },

    getCookieValue(cookie) {
        const val = document.cookie.match("(^|[^;]+)\\s*" + cookie + "\\s*=\\s*([^;]+)");
        return val ? val.pop() : "";
    },

    googleMapsUrl(address) {
        const parsed = address.split(" ").join("+");
        return `https://www.google.com/maps/place/${parsed}`;
    },

    rearrangeAppointments(appointments, startTimes, timezone, startDay = null) {
        let startDate;
        if (startDay) {
            startDate = nextOccuringDate(startDay, timezone);
        } else {
            const firstAppt = appointments[0];
            startDay = moment(firstAppt.start).format("dddd").toLowerCase();
            startDate = moment(firstAppt.start).tz(timezone);
        }

        let startTime = startTimes[`${startDay}_start_time`];
        startTime = `${startTime.substr(0, 2)}:${startTime.substr(2, 3)}`;
        const startDateTime = moment.tz(`${startDate.format("YYYY-MM-DD")} ${startTime}`, timezone).utc();

        const newAppointments = [...appointments];

        newAppointments.reduce((start, appt) => {
            const end = moment(appt.end);
            const _start = moment(appt.start);
            const duration = moment.duration(end.diff(_start))
            const durationMinutes = duration.as("minutes");

            appt.start = start.format();
            start = start.add(durationMinutes, "m");
            appt.end = start.format();

            return start;
        }, startDateTime);

        return newAppointments;
    },

    rollAppointmentsForward(appointments, timezone) {
        const currentIsoDay = moment().tz(timezone).isoWeekday();

        return [...appointments].map(appt => {
            const targetIsoStartDay = moment(appt.start).tz(timezone).isoWeekday();
            const targetIsoEndDay = moment(appt.end).tz(timezone).isoWeekday();

            const [targetStart, targetEnd] = [targetIsoStartDay, targetIsoEndDay].map(day => {
                let target;
                if (currentIsoDay <= day) {
                    target = moment().tz(timezone).utc().isoWeekday(day);
                } else {
                    target = moment().tz(timezone).add(1, "weeks").utc().isoWeekday(day);
                }

                return target.format("YYYY-MM-DD");
            });

            const parsedStart = moment(appt.start);
            const parsedEnd = moment(appt.end);

            return { ...appt, start: `${targetStart} ${parsedStart.utc().format("HH:mm")}:00.000Z`, end: `${targetEnd} ${parsedEnd.utc().format("HH:mm")}:00.000Z` }
        });
    },

    uniqueDomKey() {
        const timestamp = Date.now().toString(36);
        const randomPart = Math.random().toString(36).substr(2, 5);
        return `${timestamp}-${randomPart}`;
    }
}
