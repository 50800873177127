import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PaymentModal from './PaymentModal';
import InvoicePayments from './Payments/Index';
import ShareModal from './ShareModal';

import { toast } from 'react-toastify';
import Utils from 'Utils';

const InvoiceContext = React.createContext();

export default class InvoiceShow extends React.Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
        current_company: PropTypes.object.isRequired,
        payment_sources_url: PropTypes.string.isRequired,
        delete_source_url: PropTypes.string.isRequired,
        make_payment_url: PropTypes.string.isRequired,
        update_customer_url: PropTypes.string.isRequired,
        stripe_key: PropTypes.string.isRequired,
        account_active: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props);
        
        const line_items = props.invoice.line_items.map(li => ({ ...li, _domKey: Utils.uniqueDomKey() }))
        const invoice = { ...props.invoice, line_items }

        this.baseState = {
            invoice,
            editing: false,
            validations: {}
        }

        this.state = this.baseState
    }

    isPastDue = () => {
        if (this.state.invoice.payment_status === "billed") {
            const tz = this.props.current_company.timezone;
            return moment(this.state.invoice.due_date).tz(tz).endOf("day") < moment.tz(tz);
        }

        return false
    };

    statusLabelClass = () => {
        if (this.state.invoice.payment_status === "billed") {
            if (this.isPastDue()) {
                return "label-danger"
            }

            return "label-primary"
        } else if (this.state.invoice.payment_status === "paid") {
            return "label-success";
        }

        return "label-default"
    };

    typeLabelClass = () => {
        if (this.state.invoice.type === "service_stops") {
            return "label-primary"
        } else if (this.state.invoice.type === "repairs") {
            return "label-warning";
        }
    };

    updateLineItem = (prop, value, domKey) => {
        const lineItems = [...this.state.invoice.line_items].map(lineItem => {
            if (lineItem._domKey === domKey) {
                if (prop === "sales_tax" && !value) {
                    return { ...lineItem, [prop]: 0.0 }
                }

                return { ...lineItem, [prop]: value }
            }

            return lineItem;
        });

        this.setState({ invoice: { ...this.state.invoice, line_items: lineItems } });
    };

    subTotal = () => {
        return this.grandTotal(true, false);
    };

    totalSalesTax = (currencySymbol = true) => {
        const total = this.state.invoice.line_items.map(li => {
            const total = parseFloat(li.cost) * parseFloat(li.quantity);
            return total * li.sales_tax;
        }).reduce((a, b) => a + b);

        if (currencySymbol) return Utils.floatToCurrency(total);

        return total;
    };

    hasSalesTax = () => {
        return this.totalSalesTax(false) > 0.0;
    };

    grandTotal = (currencySymbol = true, calculateTax = true) => {
        const sums = this.state.invoice.line_items.filter(li => !li._destroy).map(li => {
            const total = parseFloat(li.cost) * parseFloat(li.quantity);
            const tax = total * li.sales_tax;

            return calculateTax ? (total + tax) : total;
        })
        const total = sums.reduce((a, b) => a + b).toFixed(2);

        if (isNaN(total)) {
            return "--"
        }

        if (currencySymbol) return Utils.floatToCurrency(parseFloat(total), currencySymbol);

        return total;
    };

    amountDue = (currencySymbol = true) => {
        const grandTotal = parseFloat(this.grandTotal(false));

        if (isNaN(grandTotal)) {
            return "--"
        }

        const amountDue = grandTotal - parseFloat(this.state.invoice.total_payments);

        if (!currencySymbol) return amountDue;

        return Utils.floatToCurrency(amountDue, currencySymbol);
    };

    lineItemGrandTotal = (lineItem) => {
        const total = parseFloat(lineItem.quantity) * parseFloat(lineItem.cost)
        const tax = parseFloat(lineItem.sales_tax || 0.0) * total;
        if (isNaN(total)) {
            return "--"
        }

        return Utils.floatToCurrency(total + tax);
    };

    addLineItem = (e) => {
        e.preventDefault();

        const line_items = [...this.state.invoice.line_items, { name: "", description: "", quantity: 0, cost: 0, grand_total: 0.0, sales_tax: 0.0, _domKey: Utils.uniqueDomKey() }];

        this.setState({ invoice: { ...this.state.invoice, line_items } });
    };

    removeLineItem = (e, key) => {
        e.preventDefault();

        const line_items = [...this.state.invoice.line_items].map(lineItem => {
            if (lineItem._domKey === key) {
                if (lineItem.id) {
                    return { ...lineItem, _destroy: true }
                }

                return null;
            }

            return { ...lineItem }
        }).filter(Boolean);

        this.setState({ invoice: { ...this.state.invoice, line_items } });
    };

    lineItemsValid = () => {
        const validations = {};
        this.state.invoice.line_items.forEach(lineItem => {
            const key = lineItem._domKey;
            ["name", "description"].forEach(prop => {
                if (lineItem[prop].length < 1) validations[key] = { ...validations[key], [prop]: true }
            });

            const floatValidations = ["quantity", "cost"];
            floatValidations.forEach(prop => {
                if (isNaN(parseFloat(lineItem[prop])) || (prop === "quantity" && lineItem[prop] < 0)) validations[key] = { ...validations[key], [prop]: true }
            });

            if (this.props.current_company.invoice_integration === "stripe") {
                if (isNaN(parseFloat(lineItem.sales_tax)) || lineItem.sales_tax < 0) {
                    validations[key] = { ...validations[key], sales_tax: true }
                }
            }
        });

        this.setState({ validations });

        for (const prop in validations) {
            if (Object.values(validations[prop]).includes(true)) {
                return false
            }
        }

        return true;
    };

    lineItemInvalid = (prop, domKey) => {
        return (this.state.validations[domKey] && this.state.validations[domKey][prop]);
    };

    toggleEdit = (e) => {
        e.preventDefault();

        this.setState({ editing: true }, () => {
            $(this.dueDateEl).datetimepicker({
                ignoreReadonly: true,
                allowInputToggle: true,
                format: "MM-DD-YY"
            });

            const parsedDueDate = moment(this.state.invoice.due_date).tz(this.props.current_company.timezone).format("MM-DD-YY");

            $(this.dueDateEl).val(parsedDueDate);

            $(this.dueDateEl).on("dp.change", (e) => {
                const formattedDate = moment(e.target.value, "MM-DD-YY").format("YYYY-MM-DD");
                const due_date = moment.tz(formattedDate, this.props.current_company.timezone).startOf("day").utc().format();

                this.setState({ invoice: { ...this.state.invoice, due_date } });
            });
        });
    };

    savePayment = (payment) => {
        this.setState({ invoice: { ...this.state.invoice, invoice_payments: [payment] } }, () => {
            this.saveInvoice();
        });
    };

    updatePayment = (payment) => {
        const invoice_payments = [...this.state.invoice.invoice_payments.map(ip => {
            if (ip.id !== payment.id) return ip;

            return { ...payment };
        })]

        this.setState({ invoice: { ...this.state.invoice, invoice_payments } });
    };

    destroyPayment = (payment) => {
        const invoice_payments = [...this.state.invoice.invoice_payments.map(ip => {
            if (ip.id !== payment.id) return ip;

            ip._destroy = true;
            return ip;
        })];

        this.setState({ invoice: { ...this.state.invoice, invoice_payments } }, () => {
            this.saveInvoice();
        });
    };

    saveInvoice = () => {
        if (this.lineItemsValid()) {
            const invoice_payments_attributes = [...this.state.invoice.invoice_payments];
            const line_items_attributes = [...this.state.invoice.line_items];

            this.setState({ loading: true })

            $.ajax({
                method: "PUT",
                url: `/invoices/${this.state.invoice.id}.json`,
                data: {
                    invoice: {
                        invoice_payments_attributes,
                        line_items_attributes,
                        due_date: this.state.invoice.due_date
                    }
                }
            }).done(savedInvoice => {
                const line_items = savedInvoice.line_items.map(li => ({ ...li, _domKey: Utils.uniqueDomKey() }))
                const invoice = { ...savedInvoice, line_items }
                this.setState({ invoice }, () => {
                    toast.success("Successfully saved Invoice!", {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
            }).fail((res) => {

            }).always(() => {
                this.setState({ loading: false, editing: false })
            });
        }
    };

    updateInvoice = (invoice) => {
        this.setState({ invoice });
    };

    applyPayment = (e) => {
        e.preventDefault()

        if (!this.props.account_active) {
            toast.error("This account must be active to apply a payment", {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            this.modalEl.show()
        }
    }

    render() {
        const integration = this.props.current_company.invoice_integration;
        return(
            <InvoiceContext.Provider
                value={{
                    integration,
                    invoice: this.state.invoice,
                    currentCompany: this.props.current_company,
                    savePayment: this.savePayment,
                    updateInvoice: this.updateInvoice,
                    stripeKey: this.props.stripe_key,
                    connectAccountId: this.props.connect_account_id,
                    paymentSourcesUrl: this.props.payment_sources_url,
                    deleteSourceUrl: this.props.delete_source_url,
                    makePaymentUrl: this.props.make_payment_url,
                    updateCustomerUrl: this.props.update_customer_url,
                    saveInvoice: this.saveInvoice,
                    updateInvoice: this.updateInvoice,
                    updatePayment: this.updatePayment,
                    destroyPayment: this.destroyPayment
                }}
            >
                <div className="invoice-show-wrapper">
                    { this.state.invoice.error_message &&
                        <div className="alert alert-danger">
                            <b>
                                { integration === "stripe" && "Error from processing payment with Stripe:" }
                                { integration !== "stripe" && `Error from syncing invoice with: ${Utils.humanize(integration)}` }
                            </b>
                            <p>
                                { this.state.invoice.error_message }
                            </p>
                        </div>
                    }

                    <div className="row page-header no-margin-top display-flex">
                        <div className="col-md-6">
                            <h1>
                                Invoice
                                <small>
                                    { ` - #${Utils.pad(this.state.invoice.invoice_number, 5)}` }
                                </small>
                            </h1>
                            <h4>
                                Account: <a href={`/accounts/${this.state.invoice.account_id}`}>{ this.state.invoice.account.contact_name }</a>
                            </h4>
                            { !this.state.editing &&
                                <h4>
                                    Due Date: { moment(this.state.invoice.due_date).tz(this.props.current_company.timezone).format("MM-DD-YY") }
                                </h4>
                            }
                            { this.state.editing &&
                                <div className="display-flex align-items-center">
                                    <h4>
                                        Due Date:
                                    </h4>
                                    <div className="form-group no-margin-bottom">
                                        <input
                                            ref={(e) => this.dueDateEl = e}
                                            type="text"
                                            className="form-control margin-5-left"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            }
                            <h4>
                                Type: <span className={`label ${this.typeLabelClass()}`}>
                                    { Utils.humanize(this.state.invoice.type) }
                                </span>
                            </h4>
                            <h4>
                                Payment Status: <span className={`label ${this.statusLabelClass()}`}>
                                    { this.isPastDue() ? "Past Due" : Utils.humanize(this.state.invoice.payment_status) }
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div className="row page-header no-margin-top padding-20-bottom">
                        <div className="col-md-12">
                            <div className="dropdown pull-right">
                                <button
                                    className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                    type="button"
                                    data-toggle="dropdown"
                                >
                                    Actions
                                    <span className="glyphicon glyphicon-chevron-down"></span>
                                </button>
                                <ul className="dropdown-menu">
                                    { !this.state.editing &&
                                        <li>
                                            <a
                                                href="#"
                                                onClick={this.toggleEdit}
                                                className="text-center padding-10"
                                            >
                                                <span className="glyphicon glyphicon-pencil"></span>
                                                &nbsp;
                                                Edit Invoice
                                            </a>
                                        </li>
                                    }
                                    { this.state.editing &&
                                        <li>
                                            <a
                                                href="#"
                                                onClick={(e) => { e.preventDefault(); this.saveInvoice() }}
                                                className="text-center padding-10"
                                            >
                                                <i className="fa fa-save"></i>
                                                &nbsp;
                                                Save Invoice
                                            </a>
                                        </li>
                                    }
                                    { this.amountDue(false) > 0.01 &&
                                        <li>
                                            <a
                                                href="#"
                                                onClick={this.applyPayment}
                                                className="text-center padding-10"
                                            >
                                                <i className="fa fa-usd"></i>
                                                &nbsp;
                                                Apply Payment
                                            </a>
                                        </li>
                                    }
                                    { integration === "stripe" &&
                                        <li>
                                            <a
                                                href="#"
                                                onClick={(e) => { e.preventDefault(); this.shareModalEl.show() }}
                                                className="text-center padding-10"
                                            >
                                                <i className="fa fa-share-square-o"></i>
                                                &nbsp;
                                                Share Link
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    { this.state.loading &&
                        <div className="display-flex flex-column margin-20-bottom">
                            <h3 className="text-center">
                                { (this.state.invoice.invoice_payments).filter(ip => !ip.id).length > 0 ? "Applying Payment..." : "Saving Invoice..." }
                            </h3>
                            <div className="text-center">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr className={this.state.editing ? "editing" : null}>
                                            <td>
                                                <h5 className="name">
                                                    Name:
                                                </h5>
                                            </td>
                                            <td className="description">
                                                <h5>
                                                    Description:
                                                </h5>
                                            </td>
                                            <td className="quantity">
                                                <h5>
                                                    Quantity:
                                                </h5>
                                            </td>
                                            <td className="unit-price">
                                                <h5>
                                                    Unit Price:
                                                </h5>
                                            </td>
                                            { ((this.props.current_company.invoice_integration === "stripe" && this.hasSalesTax()) || (this.state.editing && this.props.current_company.invoice_integration === "stripe")) &&
                                                <td className="sales-tax">
                                                    <h5>
                                                        Sales Tax:
                                                    </h5>
                                                </td>
                                            }
                                            <td className="line-total">
                                                <h5>
                                                    Line Total:
                                                </h5>
                                            </td>
                                            { (this.state.editing && this.state.invoice.line_items.filter(li => !li._destroy).length > 1) &&
                                                <td className="actions">
                                                    &nbsp;
                                                </td>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.invoice.line_items.filter(li => !li._destroy).map(lineItem => {
                                                return(
                                                    <tr key={lineItem._domKey}>
                                                        {
                                                            ["name", "description", "quantity", "cost", "sales_tax", "grand_total"].map((prop, index) => {
                                                                if (prop === "sales_tax") {
                                                                    if (this.props.current_company.invoice_integration !== "stripe") {
                                                                        return null;
                                                                    } else if (!this.hasSalesTax() && !this.state.editing) {
                                                                        return null;
                                                                    }
                                                                }

                                                                return(
                                                                    <td key={index}>
                                                                        { (!this.state.editing && prop !== "grand_total") &&
                                                                            <Fragment>
                                                                                { (prop === "cost" || prop === "grand_total") && Utils.floatToCurrency(parseFloat(lineItem[prop]))
                                                                                }
                                                                                { (prop !== "cost" && prop !== "grand_total" && prop !== "sales_tax") && lineItem[prop]
                                                                                }
                                                                                { prop === "sales_tax" && `${(parseFloat(lineItem[prop]) * 100).toFixed(3)}%` }
                                                                            </Fragment>
                                                                        }
                                                                        { prop === "grand_total" && this.lineItemGrandTotal(lineItem) }
                                                                        { (this.state.editing && prop !== "grand_total") &&
                                                                            <Fragment>
                                                                                { (prop !== "description" && prop !== "cost" && prop !== "sales_tax") &&
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={lineItem[prop]}
                                                                                            onChange={(e) => this.updateLineItem(prop, e.target.value, lineItem._domKey)}
                                                                                        />
                                                                                        { this.lineItemInvalid(prop, lineItem._domKey) &&
                                                                                            <div className="validation-wrapper">
                                                                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                                                                Invalid { Utils.humanize(prop) }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                { prop === "cost" &&
                                                                                    <Fragment>
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-addon">
                                                                                                <i className="glyphicon glyphicon-usd"></i>
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                value={lineItem[prop]}
                                                                                                onChange={(e) => this.updateLineItem(prop, e.target.value, lineItem._domKey)}
                                                                                            />
                                                                                        </div>
                                                                                        { this.lineItemInvalid(prop, lineItem._domKey) &&
                                                                                            <div className="validation-wrapper">
                                                                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                                                                Invalid Unit Price
                                                                                            </div>
                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                                { prop === "description" &&
                                                                                    <div className="form-group">
                                                                                        <textarea
                                                                                            cols="40"
                                                                                            className="form-control"
                                                                                            onChange={(e) => this.updateLineItem(prop, e.target.value, lineItem._domKey)}
                                                                                            value={lineItem[prop]}
                                                                                        >
                                                                                        </textarea>
                                                                                        { this.lineItemInvalid(prop, lineItem._domKey) &&
                                                                                            <div className="validation-wrapper">
                                                                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                                                                Description cannot be blank
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                { prop === "sales_tax" &&
                                                                                    <div className="form-group">
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                defaultValue={(parseFloat(lineItem[prop]) * 100).toFixed(3)}
                                                                                                onChange={(e) => this.updateLineItem(prop, parseFloat(e.target.value) * .01, lineItem._domKey)}
                                                                                            />
                                                                                            <div className="input-group-addon">
                                                                                                %
                                                                                            </div>
                                                                                        </div>
                                                                                        { this.lineItemInvalid(prop, lineItem._domKey) &&
                                                                                            <div className="validation-wrapper">
                                                                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                                                                Invalid Sales Tax Percentage
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </Fragment>
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                        { (this.state.editing && this.state.invoice.line_items.filter(li => !li._destroy).length > 1) &&
                                                            <td>
                                                                <a href="#" onClick={(e) => this.removeLineItem(e, lineItem._domKey)}>Remove Item</a>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            { this.state.editing &&
                                                <td>
                                                    <a href="#" onClick={this.addLineItem}>Add Line Item</a>
                                                </td>
                                            }
                                            <td colSpan="6">
                                                { this.hasSalesTax() &&
                                                    <div className="margin-20-bottom">
                                                        <h5 className="text-right">
                                                            Subtotal: <span className="color-gray">{ this.subTotal() }</span>
                                                        </h5>
                                                        <h5 className="text-right">
                                                            Total Sales Tax: <span className="color-gray">{ this.totalSalesTax() }</span>
                                                        </h5>
                                                    </div>
                                                }

                                                <div className="margin-20-bottom">
                                                    <h5 className="text-right">
                                                        Grand Total: <span className="color-gray">{ this.grandTotal() }</span>
                                                    </h5>
                                                    <h5 className="text-right">
                                                        Amount Paid: <span className="color-gray">{ Utils.floatToCurrency(parseFloat(this.state.invoice.total_payments)) }</span>
                                                    </h5>
                                                </div>

                                                <h4 className="text-right">
                                                    Amount Due: <span className="color-gray">{ this.amountDue() }</span>
                                                </h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <hr/>
                            <InvoicePayments />
                        </div>
                    </div>
                    <PaymentModal ref={(e) => this.modalEl = e} timezone={this.props.current_company.timezone} />
                    { integration === "stripe" &&
                        <ShareModal ref={(e) => this.shareModalEl = e} invoice={this.props.invoice} />
                    }
                </div>
            </InvoiceContext.Provider>
        )
    }
}

export const InvoiceConsumer = InvoiceContext.Consumer;
