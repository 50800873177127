import React, { Fragment } from 'react';

import { CreditCardConsumer } from './Index';

export default class CreditCardDetails extends React.Component {
    render() {
        return(
            <CreditCardConsumer>
                {(context) => (
                    <Fragment>
                        { context.loading &&
                            <div className="display-flex flex-column justify-content-center align-items-center">
                                <h4>
                                    Fetching Stored Payment Methods...
                                </h4>
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                        { (!context.completed && !context.loading) &&
                            <Fragment>
                                <h4 className="text-center">
                                    Stored Cards:
                                </h4>
                                { context.addingSource &&
                                    <div className="display-flex flex-column justify-content-center align-items-center margin-20-bottom">
                                        <h4>
                                            Storing new Card, please wait...
                                        </h4>
                                        <div>
                                            <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                        </div>
                                    </div>
                                }
                                { context.paymentSources.length > 0 &&
                                    <Fragment>
                                        { context.removingSource &&
                                            <div className="display-flex flex-column justify-content-center align-items-center margin-20-bottom">
                                                <h4>
                                                    Removing card, please wait...
                                                </h4>
                                                <div>
                                                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                                </div>
                                            </div>
                                        }

                                        { context.updatingSource &&
                                            <div className="display-flex flex-column justify-content-center align-items-center margin-20-bottom">
                                                <h4>
                                                    Updating default card, please wait...
                                                </h4>
                                                <div>
                                                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                                </div>
                                            </div>
                                        }

                                        <div className="display-flex flex-column align-items-center">
                                            {
                                                context.paymentSources.map((source, index) => (
                                                    <div key={index} className={`panel panel-default col-md-10 col-sm-12 col-xs-12 ${source.id === context.selectedCard && "bg-gray"}`}>
                                                        <div className="stored-card panel-body display-flex">
                                                            <div className="card-details flex-2 display-flex align-items-center">
                                                                <i className={`fa fa-2x fa-cc-${source.card.brand.toLowerCase()} margin-5-right`}></i>
                                                                <h4 className="no-margin">
                                                                    x{source.card.last4} Exp. {source.card.exp_month}/{source.card.exp_year}
                                                                </h4>
                                                                { context.defaultSource === source.id &&
                                                                    <h5 className="margin-5-left color-gray">(Default)</h5>
                                                                }
                                                            </div>
                                                            <div className="buttons-wrapper flex-1 display-flex justify-content-end">
                                                                { context.cardSelectable &&
                                                                    <button className="btn btn-success margin-5-right" onClick={() => context.selectCard(source)}>
                                                                        Select
                                                                    </button>
                                                                }
                                                                { context.defaultSource !== source.id &&
                                                                    <button className="btn btn-default margin-5-right" onClick={() => context.updateDefaultSource(source.id)}>
                                                                        Make Default
                                                                    </button>
                                                                }
                                                                <button className="btn btn-danger" onClick={(e) => context.deletePaymentSource(source)}>
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <hr/>
                                    </Fragment>
                                }
                                { context.paymentSources.length < 1 &&
                                    <Fragment>
                                        <h4 className="color-gray text-center">
                                            No Stored Payment Methods Found
                                        </h4>
                                        <div className="width-100">
                                            <hr/>
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Fragment>
                )}
            </CreditCardConsumer>
        )
    }
}
